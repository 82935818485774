<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisible" title="税表初始数据" width="1100px">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="税务报表" name="1">
        <el-table :data="list" border height="400px">
          <el-table-column align="left" prop="tableName" label="申报列表名称" min-width="240">
            <template #default="scope">
              <span>{{ $tableNameFilter(scope.row.tableName, accountSystem) }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="period" label="采集时间" min-width="150">
            <template #default="scope">
              <span>{{ $parseTime(scope.row.createdAt, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="period" label="采集账期" min-width="150">
            <template #default="scope">
              <span>{{ scope.row.period }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="name" label="采集状态" min-width="150">
            <template #default="scope">
              <span v-if="scope.row.method == 'JumpCollection'">跳过采集</span>
              <span v-else>税局采集</span>
              <span v-if="scope.row.byHand == 1">(手动调整)</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="name" label="操作" min-width="180" class-name="top_right_btns">
            <template #default="scope">
              <el-button @click="editTax(scope.row)" size="small" type="text">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="小规模期初" name="2" v-if="taxType == '1'">
        <div>{{ hintStr }}</div>
        <table class="content" cellspacing="0">
          <tr class="center weight">
            <td></td>
            <td colspan="4">专票</td>
            <td colspan="4">普票</td>
          </tr>
          <tr class="center weight">
            <td></td>
            <td colspan="2">货物劳务</td>
            <td colspan="2">服务</td>
            <td colspan="2">货物劳务</td>
            <td colspan="2">服务</td>
          </tr>
          <tr class="center weight">
            <td style="width: 12%;"></td>
            <td style="width: 11%;">金额</td>
            <td style="width: 11%;">税额</td>
            <td style="width: 11%;">金额</td>
            <td style="width: 11%;">税额</td>
            <td style="width: 11%;">金额</td>
            <td style="width: 11%;">税额</td>
            <td style="width: 11%;">金额</td>
            <td style="width: 11%;">税额</td>
          </tr>
          <tr class="center">
            <td class="weight">1%</td>
            <qzf-td v-model:amount="form.zpHwAmount1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpHwTax1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpFwAmount1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpFwTax1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppHwAmount1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppHwTax1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppFwAmount1" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppFwTax1" @change="changeList"></qzf-td>
          </tr>
          <tr class="center">
            <td class="weight">3%</td>
            <qzf-td v-model:amount="form.zpHwAmount3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpHwTax3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpFwAmount3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpFwTax3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppHwAmount3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppHwTax3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppFwAmount3" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppFwTax3" @change="changeList"></qzf-td>
          </tr>
          <tr class="center">
            <td class="weight">5%</td>
            <qzf-td v-model:amount="form.zpHwAmount5" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpHwTax5" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwAmount5" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.zpFwTax5" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppHwAmount5" disabled></qzf-td>
            <qzf-td v-model:amount="form.ppHwTax5" disabled></qzf-td>
            <qzf-td v-model:amount="form.ppFwAmount5" @change="changeList"></qzf-td>
            <qzf-td v-model:amount="form.ppFwTax5" @change="changeList"></qzf-td>
          </tr>
          <tr class="center">
            <td class="weight">免税</td>
            <qzf-td v-model:amount="form.zpHwAmountMs" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpHwTaxMs" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwAmountMs" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwTaxMs" disabled></qzf-td>
            <qzf-td v-model:amount="form.ppHwAmountMs"></qzf-td>
            <qzf-td v-model:amount="form.ppHwTaxMs"></qzf-td>
            <qzf-td v-model:amount="form.ppFwAmountMs"></qzf-td>
            <qzf-td v-model:amount="form.ppFwTaxMs"></qzf-td>
          </tr>
          <tr class="center">
            <td class="weight">0税</td>
            <qzf-td v-model:amount="form.zpHwAmount0" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpHwTax0" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwAmount0" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwTax0" disabled> </qzf-td>
            <qzf-td v-model:amount="form.ppHwAmount0"></qzf-td>
            <qzf-td v-model:amount="form.ppHwTax0"></qzf-td>
            <qzf-td v-model:amount="form.ppFwAmount0"></qzf-td>
            <qzf-td v-model:amount="form.ppFwTax0"></qzf-td>
          </tr>
          <tr class="center">
            <td class="weight">出口免税</td>
            <qzf-td v-model:amount="form.zpHwAmountCkms" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpHwTaxCkms" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwAmountCkms" disabled></qzf-td>
            <qzf-td v-model:amount="form.zpFwTaxCkms" disabled></qzf-td>
            <qzf-td v-model:amount="form.ppHwAmountCkms"></qzf-td>
            <qzf-td v-model:amount="form.ppHwTaxCkms"></qzf-td>
            <qzf-td v-model:amount="form.ppFwAmountCkms"></qzf-td>
            <qzf-td v-model:amount="form.ppFwTaxCkms"></qzf-td>
          </tr>
        </table>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button size="small" type="primary" @click="save" v-if="activeName == '2'"
          :disabled="btnStatus" :loading="saveLoading">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getInitTax, smallVatInvoiceInfo, smallVatInvoiceSave } from "@/api/taxCalculation"

export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      initTaxNo: "",
      initName: "",
      accountSystem: "",
      comId: 0,
      qysdsType: "",
      list: [],
      dialogVisible: false,
      activeName: '1',
      taxType: "",
      hintStr: "",
      form: {},
      btnStatus: true,
      saveLoading:false
    }
  },
  methods: {
    init(row) {
      this.activeName = '1'
      this.taxType = row.type
      this.initTaxNo = row.taxNo
      this.initName = row.name
      this.accountSystem = row.accountSystem
      this.comId = row.id
      this.qysdsType = row.qysdsStatus
      getInitTax({ comId: row.id }).then(res => {
        this.list = res.data.data.list
        if (this.accountSystem == '农业合作社') {
          this.list = this.list.filter(item => item.tableName != 'kj_lrb')
        }
        this.dialogVisible = true
      })
    },
    handleClick(tab) {
      if (tab.props.name == '2') {
        this.getInfo()
      }
    },
    getInfo() {
      this.btnStatus = true
      smallVatInvoiceInfo({ comId: this.comId }).then(res => {
        if (res.data.msg == 'success') {
          this.btnStatus = false
          this.form = res.data.data.info
          this.hintStr = res.data.data.prompt
        }
      })
    },
    save() {
      this.saveLoading = true
      smallVatInvoiceSave(this.form).then(res => {
        this.saveLoading = false
        if (res.data.msg == 'success') {
          this.$qzfMessage('保存成功')
          this.getInfo()
        }
      })
    },
    changeList(){
      setTimeout(() => {
        this.form.zpHwTax1 = Number((this.form.zpHwAmount1 * 0.01).toFixed(2))
        this.form.zpFwTax1 = Number((this.form.zpFwAmount1 * 0.01).toFixed(2))
        this.form.ppHwTax1 = Number((this.form.ppHwAmount1 * 0.01).toFixed(2))
        this.form.ppFwTax1 = Number((this.form.ppFwAmount1 * 0.01).toFixed(2))

        this.form.zpHwTax3 = Number((this.form.zpHwAmount3 * 0.03).toFixed(2))
        this.form.zpFwTax3 = Number((this.form.zpFwAmount3 * 0.03).toFixed(2))
        this.form.ppHwTax3 = Number((this.form.ppHwAmount3 * 0.03).toFixed(2))
        this.form.ppFwTax3 = Number((this.form.ppFwAmount3 * 0.03).toFixed(2))

        this.form.zpFwTax5 = Number((this.form.zpFwAmount5 * 0.05).toFixed(2))
        this.form.ppFwTax5 = Number((this.form.ppFwAmount5 * 0.05).toFixed(2))
      })
    },
    editTax(row) {
      //console.log(row);
      this.startAccountPeriod = row.period
      this.$store.dispatch("commons/setParam", { type: this.accountSystem })
      this.$store.dispatch("commons/setParam", { startAccountPeriod: this.startAccountPeriod })
      this.$store.dispatch("commons/setParam", { comId: this.comId })
      this.$store.dispatch("commons/setParam", { qysdsType: this.qysdsType })
      this.$store.dispatch("commons/setParam", { tableName: row.tableName })
      this.$store.dispatch("commons/setParam", { initTaxNo: this.initTaxNo })
      this.$store.dispatch("commons/setParam", { initName: this.initName })
      this.$store.dispatch("commons/setParam", { initMethod: 'collection' })
      if (row.tableName == 'kj_lrb' || row.tableName == 'kj_zcfz' || row.tableName == 'kj_xjllb') {
        this.$router.push({
          path: "/initEa/kjInitTable",   //会计报表  ok
        });
      } else if (row.tableName == 'gs_vat') {
        this.$router.push({
          path: "/initEa/initVatInvoice",   //增值税报表 一般纳税人  ok
        });
      } else if (row.tableName == 'gs_small_vat') {
        this.$router.push({
          path: "/initEa/initSmallVat",   //增值税报表 小规模  ok
        });
      } else if (row.tableName == 'gs_tax_quarter') {
        this.$router.push({
          path: "/initEa/initQysds",   //企业所得税
        });
      } else if (row.tableName == 'gs_deed') {
        this.$router.push({
          path: "/initEa/initPropertyTax",   //财产和行为纳税申报表
        });
      } else if (row.tableName == 'gs_qtsr') {
        this.$router.push({
          path: "/initEa/initTaxNeimengQtsr",   //其他收入(工会经费)
        });
      } else if (row.tableName == 'gs_sl') {
        this.$router.push({
          path: "/initEa/initTaxNeimengSl",   //水利建设专项收入
        });
      } else if (row.tableName == 'gs_ljcl') {
        this.$router.push({
          path: "/initEa/initTaxCityLife",   //城市生活垃圾处置费
        });
      } else if (row.tableName == 'gs_xfs') {
        this.$router.push({
          path: "/initEa/initXfs",   //消费费
        });
      } else if (row.tableName == 'gs_cbj') {
        this.$router.push({
          path: "/initEa/initCbj",   //残疾人就业保障金申报表
        });
      } else if (row.tableName == 'gs_whsyjsfsbbygz') {
        this.$router.push({
          path: "/initEa/initCulturalTax",   //文化事业建设费申报表
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;

  span {
    line-height: 28px;
    font-size: 13px;
  }

  tr {
    padding: 0;
    margin: 0;
  }

  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 25px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }

  .center {
    text-align: center;
  }

  .weight {
    font-weight: 600;
    background-color: #d3eff5;
  }
}
</style>
