<template>
  <el-table
    :data="list"
    style="width: 100%"
    border
    :height="contentStyleObj"
    v-loading="loading"
    :row-class-name="tableRowClassName"
    :span-method="arraySpanMethod"
  >
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column
      prop="itemName"
      label="项目大类"
      width="105"
      align="left"
      v-if="this.xinchou"
    >
    </el-table-column>
    <el-table-column prop="name" label="项目名称" width="195" align="left">
      <template #default="scope">
        <div
          v-for="(item, index) in scope.row.items"
          :key="index"
          class="div_p"
        >
          <el-tooltip :content="item.name" placement="top-start" effect="dark">
            <p :style="{ color: choiceSetting == item.type ? 'red' : '#333' }">
              {{ item.type == "code_bsbcwbb" ? "申报财务报表" : item.name }}
            </p>
          </el-tooltip>
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="remark" label="设置" width="585" align="left">
      <template #default="scope">
        <div
          v-for="(item, index) in scope.row.items"
          :key="index"
          class="div_p"
        >
          <p>
            <subject-list
              v-if="item.valueType == 'select'"
              :codes="$findCode(item.option)"
              v-model:subjectId="item.subjectId"
              v-model:fzhsItemId="item.fzhsId"
              :width="500"
              :addNotShow="item.name == '代扣社保' ? true : false"
            ></subject-list>
            <el-radio-group
              v-if="item.valueType == 'radio'"
              v-model="item.value"
              size="small"
            >
              <el-radio
                v-for="item1 in item.option"
                :label="item1.value"
                :key="item1.index"
                >{{
                  item.type == "code_bsbcwbb"
                    ? item1.name == "是"
                      ? "否"
                      : "是"
                    : item1.name
                }}</el-radio
              >
            </el-radio-group>
            <el-input
              v-if="item.valueType == 'input'"
              size="small"
              v-model="item.value"
              style="width: 500px"
            ></el-input>
          </p>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="参考" min-width="170" align="left">
      <template #default="scope">
        <div
          v-for="(item, index) in scope.row.items"
          :key="index"
          class="div_p"
        >
          <el-tooltip effect="dark" placement="top-start">
            <template #content>
              <div :style="{ maxWidth: '500px' }">{{ item.ck }}</div>
            </template>
            <p class="text_over">{{ item.ck }}</p>
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="remark" label="备注" min-width="280" align="left">
      <template #default="scope">
        <div
          v-for="(item, index) in scope.row.items"
          :key="index"
          class="div_p"
          :class="remark ? 'div_p' : 'div_p div_pheight'"
        >
          <el-tooltip
            v-if="item.temp_id != 31 && item.temp_id != 64"
            :content="item.remark"
            effect="dark"
            placement="top-start"
          >
            <template #content>
              <div :style="{ maxWidth: '500px' }">{{ item.remark }}</div>
            </template>
            <p
              class="text_over"
              v-if="item.name == '减免土地税'"
              style="color: red"
            >
              <i
                class="iconfont icon-sanjiaotanhao"
                style="color: #e6a23c; margin-right: 3px"
              ></i>
              {{ item.remark }}
            </p>

            <p class="text_over" v-else>
              {{ item.remark }}
            </p>
          </el-tooltip>
          <div
            style="
              word-break: normal;
              display: block;
              white-space: pre-wrap;
              word-wrap: break-word;
              text-align: left;
            "
            v-else
          >
            {{ item.remark }}
          </div>
        </div>
        <!-- <span
          style="word-break: normal; display: block; white-space: pre-wrap; word-wrap: break-word;text-align:left "
          >{{ scope.row.remark }}</span
        > -->
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 20px; text-align: right">
    <el-button
      type="primary"
      @click="batchSave"
      size="small"
      :loading="btnLoading"
      :disabled="!$buttonStatus('kmpz_plbc')"
      ><i class="iconfont icon-baocun"></i>批量保存</el-button
    >
  </div>
</template>

<script>
import { getComSetList, setComSet, setComSets } from "@/api/company";
export default {
  name: "settings",
  props: {
    type: {
      type: Array,
      default: [],
    },
    choiceSetting: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      dialogTableVisible: false,
      loading: false,
      btnLoading: false,
      xinchou: true,
      remark: true,
      contentStyleObj: {},
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(210);
  },
  methods: {
    getList() {
      this.loading = true;
      let param = {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        types: this.type,
      };
      getComSetList(param).then((res) => {
        this.loading = false;
        this.list = res.data.data.list ? res.data.data.list : [];
        this.handleTable();
      });
    },
    batchSave() {
      if (this.$checkSettleStatus()) return;
      let arr = [];
      this.list.map((v) => {
        v.items.map((e) => {
          arr.push(e);
        });
      });
      this.btnLoading = true;
      setComSets(arr).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogTableVisible = false;
          this.getList();
        } else {
          this.$qzfMessage("请查看设置项目", 1);
        }
      });
    },
    // 样式
    tableRowClassName({ row, rowIndex }) {
      if (this.xinchou) {
        let status = false;
        row.items.map((v) => {
          if (v.type == this.choiceSetting) {
            status = true;
            return;
          }
        });
        if (status) {
          return "warning-row";
        }
        return "";
      }
    },
    handleTable() {
      if (this.from == "basic") {
        let arr = [
          { itemName: "增值税", items: [] },
          { itemName: "凭证", items: [] },
          { itemName: "存货", items: [] },
          { itemName: "会计报表", items: [] },
          { itemName: "其他", items: [] },
        ];
        this.list.map((v) => {
          if (
            v.type == "code_jxse" ||
            v.type == "code_xxse" ||
            v.type == "code_wrzjxs" ||
            v.type == "code_sfxsbdc"
          ) {
            arr[0].items.push(v);
          } else if (
            v.type == "code_summary_inventory" ||
            v.type == "code_inventory_qy" ||
            v.type == "code_inventory_5001"
          ) {
            arr[1].items.push(v);
          } else if (
            v.type == "code_inventory_not_use_spec" ||
            v.type == "code_inventory_count" ||
            v.type == "code_inventory_zg" ||
            v.type == "code_inventroy_Aa"
          ) {
            arr[2].items.push(v);
          } else if (
            v.type == "code_bsbcwbb" ||
            v.type == "code_xjllb_sb" ||
            v.type == "code_zcfz_sj"
          ) {
            arr[3].items.push(v);
          } else {
            arr[4].items.push(v);
          }
          // if (
          //   v.type == "code_fee_1001" ||
          //   v.type == "code_cash_1001" ||
          //   v.type == "code_bnlr" ||
          //   v.type == "code_wfplr" ||
          //   v.type == "code_jmtds" ||
          //   v.type == "code_sfxy" ||
          //   v.type == "code_bnlr_xdx"  ||
          //   v.type == "code_bnlr_fxdx" ||
          //   v.type == "code_shebao_password"
          // )
        });
        this.list = arr;
      } else if (this.from == "swbb") {
        let arr = [
          { itemName: "增值税", items: [] },
          { itemName: "印花税", items: [] },
          { itemName: "企业所得税", items: [] },
          { itemName: "工会经费", items: [] },
          { itemName: "水利建设收入", items: [] },
          { itemName: "文化事业建设费", items: [] },
          { itemName: "取数设置", items: [] },
        ];
        this.list.map((v) => {
          if (
            v.type == "code_jjdj" ||
            v.type == "code_jjdj_subject_code" ||
            v.type == "code_jjdj_kcs" ||
            v.type == "code_xgmdkfpbjsfjs" ||
            v.type == "code_vat_bc" ||
            v.type == "code_vat_ckts" ||
            v.type == "code_fgs" ||
            v.type == "code_jm_tax"
          ) {
            arr[0].items.push(v);
          } else if (
            v.type == "code_yhsjsjshj" ||
            v.type == "code_yhsfu" ||
            v.type == "code_yh_in" ||
            v.type == "code_yh0"
          ) {
            arr[1].items.push(v);
          } else if (
            v.type == "code_sffgs" ||
            v.type == "code_qysds" ||
            v.type == "code_gxqy" ||
            v.type == "code_sds_ms"
          ) {
            arr[2].items.push(v);
          } else if (
            v.type == "code_ghjf_gz" ||
            v.type == "code_ghjf" ||
            v.type == "code_ghjf_0"
          ) {
            arr[3].items.push(v);
          } else if (v.type == "code_sl_0") {
            arr[4].items.push(v);
          } else if (v.type == "code_whsyjsf_0") {
            arr[5].items.push(v);
          } else if (v.type == "code_tax_sj") {
            arr[6].items.push(v);
          }
        });
        this.list = arr;
      } else if (this.from == "salary") {
        let arr = [{ itemName: "薪酬", items: [] }];
        this.list.map((v) => {
          arr[0].items.push(v);
        });
        this.list = arr;
        this.xinchou = false;
      } else if (this.from == "kjbb") {
        let arr = [
          { itemName: "会计报表", items: [] },
          { itemName: "应交税费", items: [] },
        ];
        this.list.map((v) => {
          if (v.type == "code_zcfz_cfl") {
            arr[0].items.push(v);
          } else if (v.type == "code_zcfz2221_cfl") {
            arr[1].items.push(v);
          }
        });
        this.list = arr;
        this.xinchou = false;
        this.remark = false;
      } else if (this.from == "qt") {
        let arr = [{ itemName: "其他设置", items: [] }];
        this.list.map((v) => {
          arr[0].items.push(v);
        });
        this.list = arr;
        this.xinchou = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table .cell .el-radio-group {
  white-space: pre-wrap !important;
  text-align: left;
}
.div_p {
  line-height: 27px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:last-child {
    border-bottom: none;
  }
}
.div_pheight {
  height: auto;
}
:deep(.el-radio) {
  margin-right: 14px;
}
.text_over {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<style>
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
