<template>
<div class="app-container">
  <div style="margin-bottom:10px;display: flex;justify-content: space-between;">
    <div>
      <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
      <el-input size="small" placeholder="请输入公司名称/报税手机号/编号" v-model="listQuery.name" style="width:200px" @keyup.enter="getList" clearable></el-input>
      <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
        <el-icon><Search /></el-icon> <span  >搜索</span>
      </el-button>
      <search @success="getList" @cancel="cancel">
        <el-form style="width:655px" class="styleForm" label-width="120px" :inline="true">
          <el-form-item label="纳税人类型 ：">
            <selecttaxtype v-model:type="listQuery.type" style="width:150px"></selecttaxtype>
          </el-form-item>
          <el-form-item label="选择人员 ：">
            <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:150px"></selectuser>
          </el-form-item>
          <el-form-item label="税局 ："> 
            <selectcity v-model:citys="listQuery.districtCode" style="width:150px"></selectcity>
          </el-form-item>
          <el-form-item label="授权状态 ：" v-if="activeName == '1'">   
            <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:150px"></selectAccreditStatus>
          </el-form-item>
          <el-form-item label="税种采集状态 ：" v-if="activeName == '1'"> 
            <selectCollectStatus v-model:collectStatus="listQuery.setUpStatusCj"></selectCollectStatus>
          </el-form-item>
          <el-form-item label="税表采集状态 ：" v-if="activeName == '1'"> 
            <selectPpStatus v-model:ppStatus="listQuery.setUpStatusPp"></selectPpStatus>
          </el-form-item>
          <el-form-item label="余额表上传状态 ：" v-if="activeName == '3'"> 
            <el-checkbox-group size="small" @change="changeValue" v-model="listQuery.setUpStatusJz">
              <el-checkbox-button
                v-for="item in options"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="匹配状态 ：" v-if="activeName == '3'"> 
             <el-radio-group v-model="listQuery.setUpStatusPp2">
              <el-radio label="">全部</el-radio>
              <el-radio label="1">未匹配</el-radio>
              <el-radio label="2">匹配完成</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div>
      <el-button @click="handleCateg()" size="small" type="primary" v-if="this.activeName == '1'" :disabled="!$buttonStatus('swcsh_gxsz')" :loading="gxszLoading">
        <el-icon><HelpFilled /></el-icon> <span  > 更新税种</span>
      </el-button>
      <el-button @click="handleTaxTable()" size="small" type="warning" v-if="this.activeName == '1'" :disabled="!$buttonStatus('swcsh_cxcsh')" :loading="cxcshLoading">
        <el-icon><Aim /></el-icon> <span  > 重新采集税表</span>
      </el-button> 


      <el-dropdown size="small" style="margin-left:10px;" :hide-on-click="false" v-if="this.activeName == '1'">
      <el-button size="small" type="primary">
        更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu style="text-align:center">
          <!-- <el-dropdown-item command="a" v-if="$buttonStatus('swcsh_cxgxsz')">
            <span @click="cancelTask('tax-cj-taxCategory')">撤销更新税种</span>
          </el-dropdown-item>
          <el-dropdown-item command="b" v-if="$buttonStatus('swcsh_cxcxcsh')">
            <span @click="cancelTask('tax-cj-resetTaxs')">撤销采集税表</span>
          </el-dropdown-item> -->
          <el-dropdown-item command="d" v-if="$buttonStatus('swcsh_xgzcnc')">
            <span @click="editAsset">修改资产年初</span>
          </el-dropdown-item>
          <el-dropdown-item command="c" v-if="$buttonStatus('swcsh_dc')">
            <span @click="customerExport">导出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    </div>
  </div>
  <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" v-loading="loading" border @select="handleSelectionChange" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" prop="id" type="selection" width="55" />
    <el-table-column sortable="custom" label="编号" align="center" prop="sort" width="80" fixed="left">
      <template #default="scope">
        <TableSortCell :row="scope.row" />
      </template>
    </el-table-column>
    <el-table-column prop="name" fixed label="公司名称" min-width="280" >
      <template #default="scope">
        <TagNameCopy :row="scope.row" :showUnderLine="true" :status="scope.row?.status"></TagNameCopy>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="startAccountPeriod" label="启用账期" width="80">
      <template #default="scope">
        <span>{{ scope.row.startAccountPeriod }}</span>  
      </template>
    </el-table-column>
    <el-table-column  prop="districtName" label="税局" width="65">
      <template #default="scope">
        <span>{{ scope.row.districtName }}</span>  
      </template>
    </el-table-column>
    <el-table-column prop="accountSystem" label="会计制度" width="260">
      <template #default="scope">
        {{$accountSystemFilter(scope.row.accountSystem)}}
      </template>
    </el-table-column>
    <el-table-column  prop="accountSystem" label="税种采集状态" min-width="140" v-if="this.activeName == '1'">
      <template #default="scope">
        <div class="item_icon">
            <p v-if="scope.row.setUpStatusCj == '6'|| scope.row.setUpStatusCj == '0'"><i class="iconfont icon-gantanhao"></i>未采集</p>
            <p v-if="scope.row.setUpStatusCj == '1'"><i class="iconfont icon-duihao"></i>已采集</p>
            <p v-if="scope.row.setUpStatusCj == '2'"><i class="iconfont icon-info"></i>跳过采集</p>
            <p v-if="scope.row.setUpStatusCj == '3'"><i class="iconfont icon-info"></i>排队中</p>
            <p v-if="scope.row.setUpStatusCj == '4'"><i class="iconfont icon-cuowu"></i>采集失败</p>
            <p v-if="scope.row.setUpStatusCj == '5'"><i class="iconfont icon-cuowu"></i>部分采集失败</p>
            <p v-if="scope.row.setUpStatusCj == '9'"><i class="iconfont icon-info"></i>采集中</p>
            <p v-if="scope.row.setUpStatusCj == '7'"><i class="iconfont icon-cuowu"></i>任务已撤销</p>
            <p v-if="scope.row.setUpStatusCj == '10'"><i class="iconfont icon-info"></i>跳过采集</p>
            <p v-if="scope.row.setUpStatusCj == '99'"><i class="iconfont icon-info"></i>发起中</p>
          </div>
        <el-tooltip class="item" effect="dark" v-if="scope.row.FailReason" :content="scope.row.FailReason" placement="top-start">
          <template #content>
            <div v-html="scope.row.FailReason"></div>
          </template>
          <div class="item_icon">
            <!-- <span style="border: 0;padding: 0;font-size: 13px;">{{scope.row.init.tax_category_methon}}</span> -->
            <i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;"></i>
          </div>
        </el-tooltip>
        <!-- <span v-else>{{ scope.row.init.tax_category_methon }}</span>   -->
       
      </template>
    </el-table-column>
    <el-table-column  prop="accountSystem" label="税表采集状态" min-width="160" class-name="small_cell" v-if="this.activeName == '1'">
      <template #default="scope">
        <!-- 匹配 -->
        <el-tooltip placement="top" :disabled="scope.row.PpFailReason == ''" effect="dark">
          <template #content> 
            <div v-html="Tobreak(scope.row.PpFailReason)"></div>
          </template>
          <div class="item_icon" v-if="scope.row.setUpStatusPp == 6">
            <i class="iconfont icon-gantanhao"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 1">
            <i class="iconfont icon-duihao"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 2">
            <i class="iconfont icon-duihao" style="color:#e6a23c"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
            <i class="iconfont icon-wenhao" v-if="scope.row.PpFailReason" style="color:red;margin-left: 4px;"></i>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 3 || scope.row.setUpStatusPp == 10 || scope.row.setUpStatusPp == 99">
            <i class="iconfont icon-info"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 9">
            <i class="iconfont icon-info"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 4">
            <i class="iconfont icon-cuowu"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
            <i class="iconfont icon-wenhao" v-if="scope.row.PpFailReason" style="color:red;margin-left: 4px;"></i>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusPp == 7">
            <i class="iconfont icon-cuowu"></i>
            <span>{{ setUpStatus3(scope.row.setUpStatusPp) }}</span>
            <i class="iconfont icon-wenhao" v-if="scope.row.PpFailReason" style="color:red;margin-left: 4px;"></i>
          </div>
        </el-tooltip>  
      </template>
    </el-table-column>
    <el-table-column label="财务初始化状态" header-align='center' v-if="this.activeName == '3'">
      <el-table-column prop="endCount" label="余额表上传" align="center" min-width="120px">
        <template #default="scope">
          <div class="item_icon">
            <p v-if="scope.row.setUpStatusJz == '6'"><i class="iconfont icon-gantanhao"></i>未上传</p>
            <p v-if="scope.row.setUpStatusJz == '2'"><i class="iconfont icon-duihao"></i>无需建账</p>
            <p v-if="scope.row.setUpStatusJz == '1'"><i class="iconfont icon-duihao"></i>导入</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="科目匹配" prop="init" align="center" min-width="120px">
        <template #default="scope">
          <div class="item_icon">
            <p v-if="(scope.row.setUpStatusPp == '1' || scope.row.setUpStatusPp == '10' || scope.row.setUpStatusPp == '2' || scope.row.setUpStatusPp == '4') && (scope.row.setUpStatusJz == '1' || scope.row.setUpStatusJz == '2')"><i class="iconfont icon-duihao"></i>匹配完成</p>
            <p v-else><i class="iconfont icon-gantanhao"></i>未匹配</p>
          </div>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column align="center" prop="address" label="操作" min-width="110">
      <template #default="scope">
        <el-button @click="handleUpdate(scope.row)" size="small" type="text" :disabled="!$buttonStatus('swcsh_xgswqc')" v-if="activeName == 1" icon="Edit">修改期初</el-button>
        <el-tooltip :disabled="scope.row.setUpStatusJz == '2' || scope.row.setUpStatusJz == '1' || scope.row.comCode != 0" content="请在新增账套栏目进行建账" placement="top" effect="dark">
          <el-button @click="handleUpdateSubject(scope.row)" size="small" type="text" v-if="activeName == 3" :disabled="(scope.row.setUpStatusJz == '6' || !$buttonStatus('swcsh_xgcwqc'))&& scope.row.comCode == 0" icon="Edit">修改期初</el-button>
        </el-tooltip>
        <!-- todo -->
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="initTable"/>
  </div>
  <!-- 批量重新初始化 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisibleCsh" title="重新采集税表" width="60%" >
    
    <el-form label-width="120px" >
      <el-form-item label="方式">
        <el-radio-group v-model="cshStatus">
          <el-radio :label="1">默认初始账期</el-radio>
          <el-radio :label="2">自选账期</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="cshStatus == 2" label="账期">
        <qzf-search-period v-model:period="cshPeriod" style="width:150px;"></qzf-search-period>
        <span v-if="cshStatus == 2" style="margin-left: 3px;color:#17a2b8;font-size: 13px;"><i class="iconfont icon-gantanhao1"></i>如果采集23年12账期，请选择24年1账期</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleCsh = false">取消</el-button>
        <el-button size="small" type="primary" @click="csh">确定</el-button>
      </span>
    </template>
  </el-dialog>


</div>
  <annualLossCom ref="annualLossCom" @success="getList"></annualLossCom>
  <editAsset ref="editAsset" />
  <taxBeginPeriod ref="taxBeginPeriod" />
</template>

<script>
import { eaCompanyList } from "@/api/company"
import annualLossCom from "./annualLossCom.vue"
import selectCollectStatus from "@/components/Screening/selectCollectStatus";
import selectPpStatus from "@/components/Screening/selectPpStatus";
import { assignInitializeReport } from "@/api/printSet"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { sendTask, quitTask } from "@/api/collection"
import selectcity from "@/components/Screening/selectcity";
import editAsset from './editAsset.vue'
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import taxBeginPeriod from "./taxBeginPeriod.vue";
export default {
  name:'initTable',
  props: {
    activeName: {
      type: String,
      default:''
    },
  },
  components:{
    annualLossCom,
    selectCollectStatus,
    selecttaxtype,
    selectPpStatus,
    selectcity,
    editAsset,
    selectAccreditStatus,
    TagNameCopy,
    TableSortCell,
    taxBeginPeriod
  },
  created(){
    this.listQuery.limit = localStorage.getItem('initTable') ? localStorage.getItem('initTable')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(220)
    this.initBus()
  },
  data() {
    return {
      dialogVisibleCsh: false,
      cshStatus: 1,
      cshPeriod: "",
      list:[],
      userTotal:0,
      dialogVisible:false,
      accountSystem:'',
      startAccountPeriod:'',
      comId:0,
      qysdsType:0,
      listQuery:{
        page:1,
        limit:20,
        from:'init',
        name:'',
        initType:"1",
      },
      loading:false,
      gsMainIds: undefined,
      pageStatus: true,
      initTaxNo:'',
      initName:'',
      gxszLoading:false,
      cxcshLoading:false,
      selects:[],
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      options: [
        {
          value: 1,
          label: "导入",
        },
        {
          value: 6,
          label: "未上传",
        },
        {
          value: 2,
          label: "无需建账",
        },
      ],
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  methods:{
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery={
        page:1,
        limit:originLimit,
        from:'init',
        name:'',
        initType:"1",
        subjectSc:'',
      }
      this.getList()
  },
    cancelTask(taskName){
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定撤销采集任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quitTask({taskName:taskName, comIds: this.gsMainIds}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list && this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        // this.list.forEach(res => {
        //   if(res.init.tax_category_methon == '采集中' || res.init.tax_category_methon == '排队中' || res.init.tax_category_methon == '发起中') {
        //     status = true
        //   }
          // if(res.init.tax_category_methon == '采集中' || res.init.tax_category_methon == '排队中' || res.init.tax_category_methon == '发起中') {
          //   status = true
          // }
        // })
        if(status == true) {
          if(this.activeName == '3'){
            this.listQuery.setUpStatusCj = []
            this.listQuery.setUpStatusPp = []
          }
          eaCompanyList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.userTotal = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    getList(){
      if(this.activeName == '3'){
        this.listQuery.setUpStatusCj = []
        this.listQuery.setUpStatusPp = []
      }
      this.loading = true
      eaCompanyList(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.userTotal = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.id == v.id){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleUpdate(row){
      this.$store.getters['user/comInfo'].disCode = row.districtCode
      this.$refs.taxBeginPeriod.init(row)
    },
    getGsMainIds(){
      this.gsMainIds = []
      this.selects.map(info=>{
        this.gsMainIds.push(info.id)
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.id == v.id){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getGsMainIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getGsMainIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.allChecked = false
      }
    },
    //授权状态
    filterLogin(){
      let text = this.selects.find((v) => v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1);
      return text
    },
    //更新税种
    handleCateg() {
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let fail = this.filterLogin()
      if (fail) {
        this.$qzfMessage(`${fail.name}未授权登录，请先授权再发起任务！`,1);
        return;
      }
      this.gxszLoading = true
      let param = {
        taskName: 'tax-cj-taxCategory',
        comIds: this.gsMainIds
      }
      sendTask(param).then(res => {
        this.gxszLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('采集任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    csh(){
      this.cxcshLoading = true
      let param = {
        taskName: 'tax-cj-resetTaxs',
        comIds: this.gsMainIds,
      }
      if(this.cshStatus == 2 && this.cshPeriod != ""){
        param.period = this.cshPeriod
      }
      this.dialogVisibleCsh = false
      sendTask(param).then(res => {
        this.cxcshLoading = false
        if(res.data.msg == 'success') {
         this.$qzfMessage('采集任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //重新采集税表
    handleTaxTable() {
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      //过滤授权状态
      let fail = this.filterLogin()
      if (fail) {
        this.$qzfMessage(`${fail.name}未授权登录，请先授权再发起任务！`,1);
        return;
      }
      this.dialogVisibleCsh = true
    },
    annualLoss(id){
      this.$refs.annualLossCom.getListKS(id)
    },
    Tobreak(val){
      return val.replace(/[\r\n]/g, '<br/>')
    },
    //财务初始化修改期初
    handleUpdateSubject(row){
      this.$store.dispatch("commons/setParam", {cwcshComId: row.id})
      this.$store.dispatch("commons/setParam", {cwcshComKj: row.accountSystem})
      this.$store.dispatch("commons/setParam", {cwcshComName: row.name})
      this.$store.dispatch("commons/setParam", {cwcshPeriod: row.startAccountPeriod})
      this.$store.dispatch('tagsView/delCachedViewByName', "initSubject")
      this.$router.push({
        name:'initSubject'
      })
    },
    //导出
    customerExport(){
      let param = {
        query:this.listQuery,
        ids:this.gsMainIds
      }
      assignInitializeReport(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    setUpStatus1(e) {
      let num = e;
      if (num == 1) {
        return "已采集";
      } else if (num == 6) {
        return "未采集";
      } else if (num == 2) {
        return "跳过采集";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      } else if (num == 5) {
        return "部分采集失败";
      } else if (num == 9) {
        return "采集中";
      }else if (num == 99) {
        return "发起中";
      }
    },
    setUpStatus2(e) {
      let num = e;
      if (num == 1) {
        return "已初始化";
      } else if (num == 6) {
        return "未初始化";
      }else if (num == 2) {
        return "无需初始化";
      }
    },
    setUpStatus3(e) {
      let num = e;
      if (num == 1) {
        return "采集成功";
      } else if (num == 6) {
        return "未采集";
      } else if (num == 2) {
        return "部分采集失败";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      }else if (num == 9) {
        return "采集中";
      }else if (num == 7) {
        return "任务已撤销";
      }else if (num == 10) {
        return "跳过采集";
      }else if (num == 99) {
        return "发起中";
      }
    },
    sortChange(data) {
      this.listQuery.orderBy = data.order == "descending" ? "ea_company.sort_com desc,ea_company.id desc" : "ea_company.sort_com,ea_company.id desc"
      this.getList()
    },
    //批量修改年初
    editAsset(){
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$refs.editAsset.init(this.gsMainIds)
    }
  }
}
</script>

<style lang="scss" scoped>

.top_right_btns {
  i{
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    line-height: 24px;
  }
}
.item_icon{
  display: inline-block;
  // width: 32%;
  // padding: 1px 3px;
  // padding-right: 10px;
  font-size: 13px;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
}
.el-select{
  margin-left: 0px;
}
.small_cell {
  div{
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
}
.icon-gantanhao1{
  font-size: 13px;
  margin-right: 3px;
}
</style>