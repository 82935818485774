<template>
  <el-tabs tab-position="left" style="height: 360px;" v-model="activeName">
    <el-tab-pane label="基础设置" name="1">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="right_title none-border"
      >
        <h5 style="border-bottom: 1px solid #eee;">{{ item.name }}</h5>
        <p v-if="item.remark">*温馨提示：{{ item.remark }}</p>
        <div style="margin:10px 0 20px;">
          <subject-list
            v-if="item.valueType == 'select'"
            :codes="$findCode(item.option)"
            v-model:subjectId="item.subjectId"
            v-model:fzhsItemId="item.fzhsId"
          ></subject-list>
          <el-radio-group
            v-if="item.valueType == 'radio'"
            v-model="item.value"
            size="small"
          >
            <el-radio
              v-for="item in item.option"
              :label="item.value"
              :key="item.index"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <el-input
            v-if="item.valueType == 'input'"
            size="small"
            v-model="item.value"
          ></el-input>
        </div>
      </div>
      <div class="right_title none-border">
        <h5>城建及附加合并</h5>
        <p>*温馨提示：选择“是”则城建及附加合并为一张凭证！</p>
        <div style="margin-bottom:20px;">
          <el-radio-group v-model="mergeSetInfo.taxCityVoucher">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="进项发票" name="2">
      <div class="right_title none-border">
        <h5>凭证习惯</h5>
        <p>
          *温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！
        </p>
        <el-radio-group v-model="mergeSetInfo.in">
          <div class="item_radio">
            <el-radio :label="0">按单张发票生成凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="1">按相同往来单位合并凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="2">按业务类型合并凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="3">按时间合并凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="4">按往来单位和时间合并凭证</el-radio>
          </div>
          <div class="one_item">
            <el-checkbox
              v-model="mergeSetInfo.inEntry"
              :true-label="1"
              :false-label="0"
              >结算分录合并一条</el-checkbox
            >
            <el-checkbox
              v-model="mergeSetInfo.inEntry"
              :true-label="2"
              :false-label="0"
              >合并分录(不合并往来单位)</el-checkbox
            >
          </div>
        </el-radio-group>
        <h5 style="margin-top: 20px;">摘要自定义</h5>
        <p>*温馨提示：自定义凭证摘要不可合并凭证！</p>
        <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
        <el-checkbox-group v-model="mergeSetInfo.inSummaryArr">
          <el-checkbox label="开票日期"></el-checkbox>
          <el-checkbox label="单位名称"></el-checkbox>
          <el-checkbox label="开票项目"></el-checkbox>
          <el-checkbox label="金额"></el-checkbox>
          <el-checkbox label="发票号码"></el-checkbox>
          <el-checkbox label="发票类型" value="发票类型"></el-checkbox>
        </el-checkbox-group>
      </div>
    </el-tab-pane>
    <el-tab-pane label="销项发票" name="3">
      <div class="right_title none-border">
        <h5>凭证习惯</h5>
        <p>
          *温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！
        </p>
        <el-radio-group v-model="mergeSetInfo.out">
          <div class="item_radio">
            <el-radio :label="0">按单张发票生成凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="1">按相同往来单位合并凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="2">按业务类型合并凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="3">按时间合并凭证</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="4">按往来单位和时间合并凭证</el-radio>
          </div>
          <div class="one_item">
            <el-checkbox
              v-model="mergeSetInfo.outEntry"
              :true-label="1"
              :false-label="0"
              >结算分录合并一条</el-checkbox
            >
            <el-checkbox
              v-model="mergeSetInfo.outEntry"
              :true-label="2"
              :false-label="0"
              >合并分录(不合并往来单位)</el-checkbox
            >
          </div>
        </el-radio-group>
        <h5 style="margin-top: 20px;">摘要自定义</h5>
        <p>*温馨提示：自定义凭证摘要不可合并凭证！</p>
        <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
        <el-checkbox-group v-model="mergeSetInfo.outSummaryArr">
          <el-checkbox label="开票日期"></el-checkbox>
          <el-checkbox label="单位名称"></el-checkbox>
          <el-checkbox label="开票项目"></el-checkbox>
          <el-checkbox label="金额"></el-checkbox>
          <el-checkbox label="发票号码"></el-checkbox>
          <el-checkbox label="发票类型"></el-checkbox>
          <el-checkbox label="是否代开"></el-checkbox>
        </el-checkbox-group>
      </div>
    </el-tab-pane>
    <el-tab-pane label="银行" name="4">
      <div class="right_title none-border">
        <h5>凭证习惯</h5>
        <p>
          *温馨提示：合并凭证之后不能进行拆分，且会影响现金流量表取数，请谨慎选择合并方式！
        </p>
        <el-radio-group v-model="mergeSetInfo.bank">
          <div class="item_radio">
            <el-radio :label="0">单张生成</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="1">合并生成 按业务类型（会计科目）</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="3">合并生成 按业务类型（会计科目一级）</el-radio>
          </div>
          <div class="item_radio">
            <el-radio :label="2">合并生成 按时间</el-radio>
          </div>
        </el-radio-group>

        <div class="item_lists">
          <el-checkbox
            v-model="mergeSetInfo.bankEntry"
            :true-label="1"
            :false-label="0"
            >结算银行存款分录合并一条</el-checkbox
          >
          <el-checkbox
            v-model="mergeSetInfo.dealingsEntry"
            :true-label="1"
            :false-label="0"
            >结算往来单位分录合并一条</el-checkbox
          >
        </div>

        <h5 style="margin-top: 20px;">摘要自定义</h5>
        <p>
          *温馨提示：自定义凭证摘要不可合并凭证！并且银行记录需要每条银行对应一条业务，否则摘要可能显示有误
        </p>
        <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
        <el-checkbox-group v-model="mergeSetInfo.bankSummaryArr">
          <el-checkbox label="业务类型"></el-checkbox>
          <el-checkbox label="备注"></el-checkbox>
          <el-checkbox label="交易日期"></el-checkbox>
          <el-checkbox label="单位名称"></el-checkbox>
          <el-checkbox label="金额"></el-checkbox>
        </el-checkbox-group>
      </div>
    </el-tab-pane>
    <el-tab-pane label="制作人" name="5">
      <div class="right_title none-border">
        <h5>制作人审核人</h5>
        <p>*温馨提示：为空默认登录人！</p>

        <div style="margin-bottom:20px;">
          制作人：<el-input
            style="width:200px;"
            v-model="mergeSetInfo.createUser"
            placeholder="请输入制作人"
            size="small"
          ></el-input>
          隐藏：
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="mergeSetInfo.createUserStatus"
          ></el-switch>
          （仅在打印时）
        </div>
        <div>
          审核人：<el-input
            style="width:200px;"
            v-model="mergeSetInfo.examUser"
            placeholder="请输入审核人"
            size="small"
          ></el-input>
          隐藏：
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="mergeSetInfo.examUserStatus"
          ></el-switch>
          （仅在打印时）
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="凭证生成时间" name="6">
      <div class="right_title none-border">
        <h5>凭证生成时间</h5>
        <p>*温馨提示：默认为开票日期！</p>
        <div style="margin-bottom:20px;">
          <el-radio-group v-model="timeMark">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="1">月末</el-radio>
            <el-radio :label="2">自定义日期</el-radio>
          </el-radio-group>
          <el-select v-if="timeMark == 2" v-model="customDate" filterable size="small" placeholder="请选择自定义日" style="width: 60px;margin-left: 10px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="损益凭证分录合并规则" name="7">
      <div class="right_title none-border">
        <h5>本年利润合并显示</h5>
        <p>*温馨提示：选择“是”则本年利润合并为一条明细！</p>
        <div style="margin-bottom:20px;">
          <el-radio-group v-model="mergeSetInfo.bnlrMerge">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-tab-pane>
    <!-- <el-tab-pane label="城建及附加合并" name="7">
      <div class="right_title none-border">
        <h5>城建及附加合并</h5>
        <p>*温馨提示：选择“是”则城建及附加合并为一张凭证！</p>
        <div style="margin-bottom:20px;">
          <el-radio-group v-model="mergeSetInfo.taxCityVoucher">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-tab-pane> -->
  </el-tabs>
  <div style="margin: 20px 20px 0 0 ; text-align: right;">
    <el-button
      type="primary"
      @click="saveInfo"
      size="small"
      :loading="loading"
      :disabled="!$buttonStatus('kmpz_plbc')"
      ><i class="iconfont icon-baocun"></i>批量保存</el-button
    >
  </div>
</template>

<script>
import { mergeSet, mergeInfo } from "@/api/voucher";
import { getComSetList, setComSets } from "@/api/company";
export default {
  name: "habitualSetting",
  data() {
    return {
      activeName: "1",
      checked: true,
      mergeSetInfo: {
        timeStatus: 0,
        in: 1,
        out: 1,
        bank: 1,
        outSummaryArr: [],
        inSummaryArr: [],
        bankSummaryArr: [],
        bankInfo: [],
        inEntry: true,
        outEntry: true,
        createUserStatus: 0,
        examUserStatus: 0,
      },
      comId: this.$store.getters["user/comInfo"].comId * 1,
      list: [],
      loading:false,
      timeMark:0,
      customDate:131,
      options:[
        {
          label:"01日",
          value:101
        },
        {
          label:"02日",
          value:102
        },
        {
          label:"03日",
          value:103
        },
        {
          label:"04日",
          value:104
        },
        {
          label:"05日",
          value:105
        },
        {
          label:"06日",
          value:106
        },
        {
          label:"07日",
          value:107
        },
        {
          label:"08日",
          value:108
        },
        {
          label:"09日",
          value:109
        },
        {
          label:"10日",
          value:110
        },
        {
          label:"11日",
          value:111
        },
        {
          label:"12日",
          value:112
        },
        {
          label:"13日",
          value:113
        },
        {
          label:"14日",
          value:114
        },
        {
          label:"15日",
          value:115
        },
        {
          label:"16日",
          value:116
        },
        {
          label:"17日",
          value:117
        },
        {
          label:"18日",
          value:118
        },
        {
          label:"19日",
          value:119
        },
        {
          label:"20日",
          value:120
        },
        {
          label:"21日",
          value:121
        },
        {
          label:"22日",
          value:122
        },
        {
          label:"23日",
          value:123
        },
        {
          label:"24日",
          value:124
        },
        {
          label:"25日",
          value:125
        },
        {
          label:"26日",
          value:126
        },
        {
          label:"27日",
          value:127
        },
        {
          label:"28日",
          value:128
        },
        {
          label:"29日",
          value:129
        },
        {
          label:"30日",
          value:130
        },
        {
          label:"31日",
          value:131
        },
      ]
    };
  },
  methods: {
    init() {
      this.getList();
      this.getComList();
    },
    getComList() {
      let param = {
        comId: this.comId,
        types: ["code_onkeyvoucher_salary", "code_onkeyvoucher_asset"],
      };
      getComSetList(param).then((res) => {
        this.list = res.data.data.list;
      });
    },
    getList() {
      mergeInfo({}).then((res) => {
        let merge = res.data.data.info;
        this.mergeSetInfo.in = merge.in;
        this.mergeSetInfo.out = merge.out;
        this.mergeSetInfo.inEntry = merge.inEntry;
        this.mergeSetInfo.outEntry = merge.outEntry;
        this.mergeSetInfo.bankEntry = merge.bankEntry;
        this.mergeSetInfo.dealingsEntry = merge.dealingsEntry;
        this.mergeSetInfo.bank = merge.bank;
        this.mergeSetInfo.examUser = merge.examUser;
        this.mergeSetInfo.createUser = merge.createUser;
        this.mergeSetInfo.examUserStatus = merge.examUserStatus;
        this.mergeSetInfo.createUserStatus = merge.createUserStatus;
        this.mergeSetInfo.timeStatus = merge.timeStatus;
        this.mergeSetInfo.taxCityVoucher = merge.taxCityVoucher
        this.mergeSetInfo.bnlrMerge = merge.bnlrMerge
        this.timeMark = merge.timeStatus == 0 || merge.timeStatus == 1 ? merge.timeStatus : 2
        this.customDate = this.timeMark == 2 ? merge.timeStatus : 131
        let arrIn = [];
        merge.inSummary.split(",").map((v) => {
          if (v && v != "默认业务类型") {
            arrIn.push(v);
          }
        });
        let arrOut = [];
        merge.outSummary.split(",").map((v) => {
          if (v && v != "默认业务类型") {
            arrOut.push(v);
          }
        });
        let arrBank = [];
        merge.bankSummary.split(",").map((v) => {
          if (v && v != "默认业务类型") {
            arrBank.push(v);
          }
        });
        this.mergeSetInfo.outSummaryArr = arrOut;
        this.mergeSetInfo.inSummaryArr = arrIn;
        this.mergeSetInfo.bankSummaryArr = arrBank;
      });
    },
    saveInfo() {
      if(this.$checkSettleStatus()) return
      this.mergeOk();
      this.saveList();
    },
    mergeOk() {
      this.loading = true
      this.mergeSetInfo.in = this.mergeSetInfo.in * 1;
      this.mergeSetInfo.out = this.mergeSetInfo.out * 1;
      this.mergeSetInfo.bank = this.mergeSetInfo.bank * 1;
      this.mergeSetInfo.inEntry = this.mergeSetInfo.inEntry * 1;
      this.mergeSetInfo.outEntry = this.mergeSetInfo.outEntry * 1;
      this.mergeSetInfo.bankEntry = this.mergeSetInfo.bankEntry * 1;
      this.mergeSetInfo.dealingsEntry = this.mergeSetInfo.dealingsEntry * 1;
      this.mergeSetInfo.inSummary = this.mergeSetInfo.inSummaryArr.join(",");
      this.mergeSetInfo.outSummary = this.mergeSetInfo.outSummaryArr.join(",");
      this.mergeSetInfo.bankSummary = this.mergeSetInfo.bankSummaryArr.join(
        ","
      );
      this.mergeSetInfo.timeStatus = this.timeMark == 2 ? this.customDate : this.timeMark
      mergeSet(this.mergeSetInfo).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
        }
      });
    },
    saveList() {
      setComSets(this.list).then((res) => {
        if (res.data.msg != "success") {
          this.$qzfMessage("请查看设置项目", 1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.right_title {
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
}
.right_title h5 {
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 10px;
}
.right_title p {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
}
.item_radio,
.one_item {
  width: 100%;
  padding-left: 30px;
  height: 25px;
}
.item_radio2 {
  width: 100%;
  padding-left: 60px;
}
.item_lists {
  width: 100%;
  padding-left: 60px;
}
.el-radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
