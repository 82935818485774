<template>
  <div class="app-container">
    <div
      style="margin-bottom:10px;display: flex;justify-content: space-between;"
    >
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-date-picker
          v-model="listQuery.year"
          type="year"
          value-format="YYYY"
          placeholder="选择年份"
          @change="getList"
          size="small"
          style="width:80px;margin: 0 5px;"
          :clearable="false"
        />
        <el-input
          size="small"
          placeholder="请输入公司名称/报税手机号/编号"
          v-model="listQuery.name"
          style="width:200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right:10px"
        >
          <el-icon><Search /></el-icon> <span>搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin:21px 0px 0px 6px;width:410px"
            class="styleForm"
          >
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">
              <selecttaxtype
                v-model:type="listQuery.type"
                style="width:150px"
              ></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
              <selectuser
                code="bookkeeping"
                v-model:userId="listQuery.userId"
                style="width:150px"
              ></selectuser>
            </el-form-item>
            <el-form-item label="税局 ：" :label-width="formLabelWidth">
              <selectcity
                v-model:citys="listQuery.districtCode"
                style="width:150px"
              ></selectcity>
            </el-form-item>
            <el-form-item label="授权状态 ：" :label-width="formLabelWidth">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:150px"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="采集状态 ：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.allStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button type="primary" size="small" icon="Aim" @click="sendCj" plain :disabled="!$buttonStatus('swcsh_mbkscj')" :loading="btnLoading"
          >弥补亏损采集</el-button
        >
      </div>
    </div>
    <el-table
      stripe
      :data="list"
      :height="contentStyleObj"
      style="width: 100%"
      v-loading="loading"
      border
      @select="handleSelectionChange"
      @sort-change="sortChange"
      @select-all="handleSelectionChangeAll"
      ref="tableScroll"
      id="tableLazyLoad"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="50" />
      <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column prop="name" fixed label="公司名称" min-width="280">
        <template #default="scope">
          <TagNameCopy :row="scope.row"  idKey="comId" :showUnderLine="true" :status="scope.row?.status"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="startAccountPeriod"
        label="初始账期"
        width="80"
      >
      </el-table-column>
      <el-table-column prop="districtName" label="税局" width="65">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="accountSystem" label="会计制度" width="260">
        <template #default="scope">
          {{$accountSystemFilter(scope.row.accountSystem)}}
        </template>
      </el-table-column>
      <el-table-column
        prop="districtName"
        label="弥补亏损采集状态"
        min-width="140"
      >
        <template #default="scope">
          <span v-if="scope.row.image" class="demo-image__preview" >
            <i class="iconfont icon-picture" style="color:red;font-size:16px;" @click="openCj(scope.row, scope.$index)" v-if="scope.row.bussinessStatus == 3"></i>
            <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="openCj(scope.row, scope.$index)" v-else></i>
          </span>
          <div class="item_icon">
            <p>
              <i v-if="!scope.row.image" :class="$batchSendIconCj(scope.row.taskStatus, scope.row.bussinessStatus)"></i>
              <el-tooltip v-if="scope.row.bussinessLog || scope.row.errLog" effect="dark"  placement="top-start">
              <template #content>
                  <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''"></div>
              </template>
              <div style="display: inline-block;">
                  <span :class="$batchSendWordSb(scope.row.taskStatus, scope.row.bussinessStatus)">{{$batchSendCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.bussinessStatus == 3 && scope.row.bussinessLog" style="color:#f56c6c;margin:0 0 0 4px;"></i>
              </div>
              </el-tooltip>
              <span v-else>
              <span :class="$batchSendWordSb(scope.row.taskStatus, scope.row.bussinessStatus)">{{$batchSendCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
              </span>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="ndks"
        label="弥补亏损"
        min-width="120"
      >
        <template #default="scope">
          <span
            @click="annualLoss(scope.row.comId)"
            style="cursor:pointer;color:#39b0d2;text-decoration:underline"
            v-if="$buttonStatus('swcsh_xgmbks')"
          >
            <i class="iconfont icon-bianji"></i>
          </span>
          <span style="cursor:pointer;color:#39b0d2;text-decoration:underline">
            {{ scope.row.ndks }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
        type="initNdks"
      />
    </div>
  </div>
  <annualLossCom ref="annualLossCom" @success="getList"></annualLossCom>
</template>

<script>
import { companyNdksList } from "@/api/company";
import annualLossCom from "./annualLossCom.vue";
import { getLastYear } from "../../../utils";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import { sendTask } from "@/api/collection";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
export default {
  name: "initNdks",
  components: {
    annualLossCom,
    selecttaxtype,
    selectcity,
    selectAccreditStatus,
    TableSortCell,
    TagNameCopy
  },
  data() {
    return {
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        year: getLastYear(),
        initType:"1",
      },
      loading: false,
      formLabelWidth: "100px",
      pageStatus: true,
      sels: [],
      options: [
        {
          value: "task-0",
          label: '未采集',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '采集中',
        },
        {
          value: "task-4",
          label: '失败',
        },
        {
          value: "business-2",
          label: '成功',
        },
      ],
      btnLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false
    };
  },
  activated() {
    this.pageStatus = true;
  },
  deactivated() {
    this.pageStatus = false;
  },
  created() {
    this.listQuery.limit = localStorage.getItem('initNdks') ? localStorage.getItem('initNdks')*1 : 20
    this.getList();
    this.contentStyleObj = this.$getHeight(220);
    this.initBus();
  },
  methods: {
    initBus() {
      this.$bus.on("refreshTask", () => {
        if (!this.pageStatus) {
          return;
        }
        if (this.sels && this.sels.length != 0) {
          return;
        }
        if (this.list && this.list.length == 0) {
          return;
        }
        //刷新业务
        let status = false;
        this.list.forEach((res) => {
          if ([1,2,99].includes(res.taskStatus)) {
            status = true;
          }
        });
        if (status == true) {
          companyNdksList(this.listQuery).then((res) => {
            if (res.data.msg == "success") {
              this.tableData = res.data.data.list ? res.data.data.list : [];
              this.total = res.data.data.total;
              this.setScroll()
            }
          });
        }
      });
    },
    getList() {
      this.loading = true;
      companyNdksList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.setScroll()
        }
      });
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.sels = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.sels.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.sels.length != 0){
              that.sels.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.sels = newArr
      }else{
        this.sels = val;
      }
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.sels = this.tableData
        this.allChecked = true
      }else{
        this.sels = []
        this.allChecked = false
      }
    },
    annualLoss(id) {
      this.$refs.annualLossCom.getListKS(id);
    },
    sendCj() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.btnLoading = true;
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.comId);
      });
      let param = {
        comIds: ids,
        taskName: "tax-cj-ndks",
        period:this.listQuery.year
      };
      sendTask(param).then((res) => {
        this.btnLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("采集任务已发起", 3);
          this.getList();
          this.$queueMessage(res.data.data.queue);
        }
      });
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList()
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page: 1,
        limit: originLimit,
        name: "",
        year: getLastYear(),
        initType:"1",
      }
      this.getList()
    }
  },
};
</script>

<style lang="scss" scoped>
.item_icon {
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  i {
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
}
.el-select {
  margin-left: 0px;
}
</style>
