<template>
  <div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础科目配置" name="1">
        <settingSubject
          :type="subjectType"
          :choiceSetting="choiceSetting"
          ref="settingSubject"
          from="basic"
        />
      </el-tab-pane>
      <el-tab-pane label="薪酬设置" name="2">
        <settingSubject
          ref="salarySubject"
          :type="[
            'code_xzgs',
            'code_gz_merge',
            'code_salary_gs',
            'code_salary_sb',
            'code_salary_wages',
            'code_salary_wages_admin',
            'code_salary_sc',
            'code_salary_zz',
            'code_salary_xs',
            'code_salary_lw',
            'code_salary_qt',
            'code_salary_dksb',
            'code_salary_dkgjj',
            'code_salary_djgs',
            'code_salary_dkqt',
            'code_salary_lwbc_in',
            'code_salary_lwbc_out',
            'code_jysd_in',
            'code_jysd_out',
            'code_salary_only_in',
            'code_salary_only_out',
            'code_salary_gcsg',
          ]"
          :choiceSetting="choiceSetting"
          from="salary"
        />
      </el-tab-pane>
      <el-tab-pane label="凭证设置" name="3">
        <habitualSetting ref="habitualSetting" />
      </el-tab-pane>
      <el-tab-pane label="税金计提科目设置" name="9">
        <accrualAccount ref="accrualAccount" />
      </el-tab-pane>
      <el-tab-pane label="税务报表设置" name="4">
        <settingSubject
          ref="taxSubject"
          :type="[
            'code_jjdj',
            'code_jjdj_subject_code',
            'code_sffgs',
            'code_xgmdkfpbjsfjs',
            'code_jjdj_kcs',
            'code_jm_tax',
            'code_vat_bc',
            'code_vat_ckts',
            'code_yhsfu',
            'code_yh_in',
            'code_qysds',
            'code_gxqy',
            'code_sds_ms',
            'code_fgs',
            'code_ghjf',
            'code_ghjf_gz',
            'code_ghjf_0',
            'code_yh0',
            'code_yhsjsjshj',
            'code_sl_0',
            'code_whsyjsf_0',
            'code_tax_sj'
          ]"
          from="swbb"
        />
      </el-tab-pane>
      <el-tab-pane label="会计报表设置" name="5">
        <settingSubject
          ref="kjbbSubject"
          :type="['code_zcfz_cfl', 'code_zcfz2221_cfl']"
          from="kjbb"
        />
      </el-tab-pane>
      <!-- <el-tab-pane label="其他设置" name="8">
        <settingSubject ref="qtSubject" :type="['code_sfzyxschxx','']" from="qt"/>
      </el-tab-pane> -->
      <el-tab-pane label="账套备份" name="7">
        <div class="common_dialog_box clearfix">
          <div class="common_title">账套备份</div>
          <div class="item_one clearfix">
            <qzf-period
              v-model:period="listQuery.startPeriod"
              style="width:110px"
            ></qzf-period>
            <span>-</span>
            <qzf-period
              v-model:period="listQuery.endPeriod"
              style="width:110px;margin-left: 4px;"
            ></qzf-period>
            <span style="font-size: 14px;margin-left: 10px;"
              >备份文件格式：</span
            >
            <el-radio
              style="position: relative;top:2px"
              v-model="listQuery.types"
              label="excel"
              size="small"
              >excel</el-radio
            >
            <el-radio
              style="position: relative;top:2px"
              v-model="listQuery.types"
              label="pdf"
              size="small"
              >pdf</el-radio
            >
            <el-button
              type="primary"
              size="small"
              @click="backups"
              :loading="btnLoading"
              :disabled="!$buttonStatus('ztbf_bf')"
              >备份</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="远程查账" name="6" v-if="showStatus">
        <bindApplet ref="bindApplet" />
      </el-tab-pane>
      <el-tab-pane label="单据/税款确认设置" name="10" v-if="orgId == 3576">
        <confirmSetting ref="confirmSetting" :comId="listQuery.comId"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import settingSubject from "./components/settingSubject.vue";
import bindApplet from "../company/components/bindApplet.vue";
import habitualSetting from "../bookkeeps/components/habitualSetting.vue";
import { accountBackups } from "@/api/export";
import accrualAccount from "./components/accrualAccount.vue";
import confirmSetting from "./components/confirmSetting.vue";
export default {
  name: "habbitSettingBook",
  components: {
    settingSubject,
    bindApplet,
    habitualSetting,
    accrualAccount,
    confirmSetting
  },
  data() {
    return {
      activeName: "1",
      listQuery: {
        startPeriod: "",
        endPeriod: "",
        paperType: "A4-H",
        types: "excel",
        comId: this.$store.getters["user/comInfo"].comId * 1,
      },
      btnLoading: false,
      choiceSetting: "",
      subjectType: [],
      comKj: this.$store.getters["user/comInfo"].comKj,
      showStatus:true,
      orgId: this.$store.getters["user/user"].orgId,

    };
  },
  watch: {
    // 检测路由变化
    $route() {
      if (this.$route.query.from) {
        if (this.$route.query.from == "未设置进项税额科目") {
          this.activeName = "1";
          this.choiceSetting = "code_jxse";
        } else if (this.$route.query.from == "未设置未认证进项税额科目") {
          this.activeName = "1";
          this.choiceSetting = "code_wrzjxs";
        } else if (this.$route.query.from == "未设置销项税额科目") {
          this.activeName = "1";
          this.choiceSetting = "code_xxse";
        } else if (this.$route.query.from == "未设置费用现金结算科目") {
          this.activeName = "1";
          this.choiceSetting = "code_fee_1001";
        } else if (this.$route.query.from == "未设置现金结算科目") {
          this.activeName = "1";
          this.choiceSetting = "code_cash_1001";
        } else if (this.$route.query.from == "未设置暂估科目") {
          this.activeName = "1";
          this.choiceSetting = "code_inventory_zg";
        } else if (this.$route.query.from == "未设置生产成本科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_sc";
        } else if (this.$route.query.from == "未设置制造费用科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_zz";
        } else if (this.$route.query.from == "未设置劳务成本科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_lw";
        } else if (this.$route.query.from == "未设置销售费用科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_xs";
        } else if (this.$route.query.from == "未设置薪酬类其他科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_qt";
        } else if (this.$route.query.from == "未设置管理费用科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_wages_admin";
        } else if (this.$route.query.from == "未设置代扣社保科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_dksb";
        } else if (this.$route.query.from == "未设置代扣公积金科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_dkgjj";
        } else if (this.$route.query.from == "未设置代扣个人所得税科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_djgs";
        } else if (this.$route.query.from == "未设置应付职工薪酬科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_wages";
        } else if (this.$route.query.from == "未设置代扣其他科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_dkqt";
        } else if (this.$route.query.from == "未设置劳务报酬贷科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_lwbc_out";
        } else if (this.$route.query.from == "未设置劳务报酬借科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_lwbc_in";
        } else if (this.$route.query.from == "未设置全年一次性奖金贷科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_only_out";
        } else if (this.$route.query.from == "未设置全年一次性奖金借科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_only_in";
        } else if (this.$route.query.from == "未设置经营所得贷科目") {
          this.activeName = "2";
          this.choiceSetting = "code_jysd_out";
        } else if (this.$route.query.from == "未设置工程施工科目") {
          this.activeName = "2";
          this.choiceSetting = "code_salary_gcsg";
        }else if (this.$route.query.from == "未设置经营所得借科目") {
          this.activeName = "2";
          this.choiceSetting = "code_jysd_in";
        } else if (this.$route.query.from == "请先完成计提科目配置") {
          this.activeName = "9";
        }

        this.getList();
      }
    },
  },
  created() {
    this.initBus()
    if (this.comKj == "民办非") {
      this.subjectType = [
        "code_jxse",
        "code_wrzjxs",
        "code_xxse",
        "code_cash_1001",
        "code_fee_1001",
        "code_inventory_not_use_spec",
        "code_inventory_zg",
        "code_sfxsbdc",
        "code_summary_inventory",
        "code_inventory_qy",
        "code_inventory_5001",
        "code_inventory_count",
        "code_bsbcwbb",
        "code_xjllb_sb",
        "code_zcfz_sj",
        "code_jmtds",
        "code_sfxy",
        "code_bnlr_xdx",
        "code_bnlr_fxdx",
        "code_shebao_password",
        'code_inventroy_Aa'
      ];
    } else {
      this.subjectType = [
        "code_jxse",
        "code_wrzjxs",
        "code_xxse",
        "code_cash_1001",
        "code_fee_1001",
        "code_inventory_not_use_spec",
        "code_inventory_zg",
        "code_sfxsbdc",
        "code_summary_inventory",
        "code_inventory_qy",
        "code_inventory_5001",
        "code_inventory_count",
        "code_bsbcwbb",
        "code_xjllb_sb",
        "code_zcfz_sj",
        "code_bnlr",
        "code_wfplr",
        "code_jmtds",
        "code_sfxy",
        "code_shebao_password",
        'code_inventroy_Aa'
      ];
    }
    this.showStatus = window.location.hostname == 'acc1.yinuojizhang.com' ? false : true
  },
  mounted() {
    // 跳转过来的设置
    let fromType = this.$route.query.from;
    if (
      fromType == "未设置进项税额科目" ||
      fromType == "未设置未认证进项税额科目" ||
      fromType == "未设置销项税额科目" ||
      fromType == "未设置费用现金结算科目" ||
      fromType == "未设置现金结算科目" ||
      fromType == "未设置暂估科目"
    ) {
      this.activeName = "1";
      if (fromType == "未设置进项税额科目") {
        this.choiceSetting = "code_jxse";
      } else if (fromType == "未设置未认证进项税额科目") {
        this.choiceSetting = "code_wrzjxs";
      } else if (fromType == "未设置销项税额科目") {
        this.choiceSetting = "code_xxse";
      } else if (fromType == "未设置费用现金结算科目") {
        this.choiceSetting = "code_fee_1001";
      } else if (fromType == "未设置现金结算科目") {
        this.choiceSetting = "code_cash_1001";
      } else if (fromType == "未设置暂估科目") {
        this.choiceSetting = "code_inventory_zg";
      }
    } else if (
      fromType == "未设置生产成本科目" ||
      fromType == "未设置制造费用科目" ||
      fromType == "未设置劳务成本科目" ||
      fromType == "未设置销售费用科目" ||
      fromType == "未设置薪酬类其他科目" ||
      fromType == "未设置管理费用科目" ||
      fromType == "未设置代扣社保科目" ||
      fromType == "未设置代扣公积金科目" ||
      fromType == "未设置代扣个人所得税科目" ||
      fromType == "未设置应付职工薪酬科目" ||
      fromType == "未设置代扣其他科目" ||
      fromType == "未设置代扣个人所得税科目" ||
      fromType == "未设置劳务报酬贷科目" ||
      fromType == "未设置劳务报酬借科目" ||
      fromType == "未设置全年一次性奖金贷科目" ||
      fromType == "未设置全年一次性奖金借科目" ||
      fromType == "未设置经营所得贷科目" ||
      fromType == "未设置经营所得借科目" || 
      fromType == "未设置工程施工科目"
    ) {
      this.activeName = "2";
      if (fromType == "未设置生产成本科目") {
        this.choiceSetting = "code_salary_sc";
      } else if (fromType == "未设置制造费用科目") {
        this.choiceSetting = "code_salary_zz";
      } else if (fromType == "未设置劳务成本科目") {
        this.choiceSetting = "code_salary_lw";
      } else if (fromType == "未设置销售费用科目") {
        this.choiceSetting = "code_salary_xs";
      } else if (fromType == "未设置薪酬类其他科目") {
        this.choiceSetting = "code_salary_qt";
      } else if (fromType == "未设置管理费用科目") {
        this.choiceSetting = "code_salary_wages_admin";
      } else if (fromType == "未设置代扣社保科目") {
        this.choiceSetting = "code_salary_dksb";
      } else if (fromType == "未设置代扣公积金科目") {
        this.choiceSetting = "code_salary_dkgjj";
      } else if (fromType == "未设置代扣个人所得税科目") {
        this.choiceSetting = "code_salary_djgs";
      } else if (fromType == "未设置应付职工薪酬科目") {
        this.choiceSetting = "code_salary_wages";
      } else if (fromType == "未设置代扣其他科目") {
        this.choiceSetting = "code_salary_dkqt";
      } else if (fromType == "未设置劳务报酬贷科目") {
        this.choiceSetting = "code_salary_lwbc_out";
      } else if (fromType == "未设置劳务报酬借科目") {
        this.choiceSetting = "code_salary_lwbc_in";
      } else if (fromType == "未设置全年一次性奖金贷科目") {
        this.choiceSetting = "code_salary_only_out";
      } else if (fromType == "未设置全年一次性奖金借科目") {
        this.choiceSetting = "code_salary_only_in";
      } else if (fromType == "未设置经营所得贷科目") {
        this.choiceSetting = "code_jysd_out";
      } else if (fromType == "未设置经营所得借科目") {
        this.choiceSetting = "code_jysd_in";
      }else if (fromType == "未设置工程施工科目") {
        this.choiceSetting = "code_salary_gcsg";
      }
    } else if (fromType == "请先完成计提科目配置") {
      this.activeName = "9";
    }
    this.getList();
  },
  methods: {
    initBus(){
      this.$bus.off("habbitSettingBookUpdate")
      this.$bus.on("habbitSettingBookUpdate", (val) => {
        this.getList()
      });
    },
    getList() {
      if (this.activeName == "1") {
        this.$refs.settingSubject.getList();
      } else if (this.activeName == "2") {
        this.$refs.salarySubject.getList();
      } else if (this.activeName == "3") {
        this.$refs.habitualSetting.init();
      } else if (this.activeName == "4") {
        this.$refs.taxSubject.getList();
      } else if (this.activeName == "5") {
        this.$refs.kjbbSubject.getList();
      } else if (this.activeName == "6") {
        this.$refs.bindApplet.init();
      } else if (this.activeName == "8") {
        this.$refs.qtSubject.getList();
      } else if (this.activeName == "9") {
        this.$refs.accrualAccount.getList();
      } else if (this.activeName == "10") {
        this.$refs.confirmSetting.getList();
      }
    },

    handleClick(tab) {
      this.activeName = tab.props.name;
      this.getList();
    },
    backups() {
      if (!this.listQuery.endPeriod || !this.listQuery.startPeriod) {
        this.$qzfMessage("请正确选择备份账期期起和备份账期期止", 1);
        return;
      }
      this.btnLoading = true;
      accountBackups(this.listQuery).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          if (res.data.data.msg) {
            this.$qzfMessage(res.data.data.msg);
          } else {
            window.open(res.data.data.path);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title h3 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #657180;
}
h3::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.each_set {
  font-size: 13px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.el-radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.each_des {
  display: inline-block;
  width: 160px;
  text-align: left;
}
.common_dialog_box .common_title {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
</style>
