<template>
  <div>
    <table class="content content_head" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td style="width: 25%">项目名称</td>
        <td style="width: 25%">设置</td>
        <td style="width: 25%">参考</td>
        <td style="width: 25%">备注</td>
      </tr>
      <tr>
        <td>单据确认</td>
        <td>
          <el-radio-group v-model="info.ackDjStatus">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="2">无需确认</el-radio>
            <el-radio :label="1">手工确认</el-radio>
          </el-radio-group>
        </td>
        <td></td>
        <td>默认取通用设置-申报设置-单据确认设置处的配置</td>
      </tr>
      <tr>
        <td>税款确认</td>
        <td>
          <el-radio-group v-model="info.ackTaxStatus">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="2">无需确认</el-radio>
            <el-radio :label="1">手工确认</el-radio>
          </el-radio-group>
        </td>
        <td></td>
        <td>默认取通用设置-申报设置-税款确认设置处的配置</td>
      </tr>
    </table>
    <div style="margin-top: 20px; text-align: right">
      <el-button
        type="primary"
        @click="batchSave"
        size="small"
        :loading="btnLoading"
        ><i class="iconfont icon-baocun"></i>保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { eaCompanyInfo, batchSetSure } from "@/api/company";
const props = defineProps({
  comId: {
    type: Number,
    default: 0,
  },
});
const info = ref({});
const btnLoading = ref(false);
const getList = () => {
  eaCompanyInfo({ comId: props.comId }).then((res) => {
    if (res.data.msg == "success") {
      info.value = res.data.data.info;
    }
  });
};
const batchSave = () => {
  btnLoading.value = true;
  let param = {
    comIds: [props.comId],
    djStatus: info.value.ackDjStatus,
    skStatus: info.value.ackTaxStatus,
  };
  batchSetSure(param).then((res) => {
    btnLoading.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("设置成功");
      getList();
    }
  });
};
defineExpose({
  getList,
});
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    line-height: 25px;
    padding: 5px 8px;
    color: #333;
    font-size: 13px;
  }
}
</style>
